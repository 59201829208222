import React from 'react';
import { useSelector } from 'react-redux';
import JumperMenu from './JumperMenu';

const ModuleJumper = (props) => {

  const orderedPages = useSelector((state) => state.pages.orderedPages);
  const translations = useSelector((state) => state.translations);
  const currentLang = useSelector((state) => state.navigation.currentLang);

  const menuItemText = (page, index) => {
    const content = JSON.parse(page.content);
    const moduleText = content.nonModule || translations['module']?.[currentLang] || 'Module';
    const showModuleOrder = !Boolean(content.nonModule);

    return moduleText + (showModuleOrder ? ` ${index + 1}` : '') + `: ${page.title}`;
  };

  const items = orderedPages.map((page, index) => {
    return { id: page.id, label: menuItemText(page, index) };
  });

  return <JumperMenu items={items} />
};

export default ModuleJumper;